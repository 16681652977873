import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async getPushEvent() {
    const url = '/push-event';
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async updatePushEvent(data) {
    const url = '/push-event';
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
}