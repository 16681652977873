import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async getWebPushVapidPublicKey() {
    const url = '/webPush/vapid-public-key';
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async subScribeWebPush(data) {
    const url = '/webPush/subscribe';
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async sendWebPush(data) {
    const url = '/webPush/send-push-Notification';
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async unsubScribeWebPush(data) {
    const url = '/webPush/unsubscribe';
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async makeWebPushSchedule(data) {
    const url = '/webPush/push-schedule';
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async updateWebPushSchedule(data, webPushScheduleId) {
    const url = `/webPush/push-schedule?webPushScheduleId=${webPushScheduleId}`;
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async updateWebPushScheduleTime(data, webPushScheduleId) {
    const url = `/webPush/push-schedule-time?webPushScheduleId=${webPushScheduleId}`;
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async deleteWebPushSchedule(webPushScheduleId) {
    const url = `/webPush/push-schedule?webPushScheduleId=${webPushScheduleId}`;
    const response = await customAxios({
      url, method: 'DELETE', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
}