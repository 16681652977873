export const DEFAULT_COLOR = '#51518e'
export const DEFAULT_LIGHT_COLOR = '#f3e3fb'
export const CALENDAR_COLOR = '#b799fb'

export const SERVER_TOKEN_KEY = process.env.VUE_APP_SERVER_TOKEN_KEY
export const TOKEN_PASSWORD = process.env.VUE_APP_TOKEN_PASSWORD
export const KAKAO_APP_KEY = process.env.VUE_APP_KAKAO_APP_KEY

export const COLOR_LIST = [
	{ label: '빨강색', color: 'rgb(244, 143, 177)' },
	{ label: '빨강색2', color: '#fcc5c5' },
	{ label: '주황색', color: '#fadebe' },
	{ label: '노랑색', color: '#fcf4a9' },
	{ label: '연두색', color: '#d5fca9' },
	{ label: '연녹색', color: 'rgb(165, 214, 167)' },
	{ label: '파란색', color: '#98c7fc' },
	{ label: '청록색', color: 'rgb(128, 222, 234)' },
	{ label: '보라색', color: '#d4a9fc' },
	{ label: '핑크색', color: '#f7afe7' },
	{ label: '갈색', color: 'rgb(188, 170, 164)' },
	{ label: '회색', color: '#c9c9c9' },
	{ label: '연한회색', color: 'rgb(238, 238, 238)' },
];

export const memoGuideItems = [
	{ src: require('@/assets/images/guide/memo1.png') },
	{ src: require('@/assets/images/guide/memo2.png') },
	{ src: require('@/assets/images/guide/memo3.png') },
	{ src: require('@/assets/images/guide/memo4.png') },
	{ src: require('@/assets/images/guide/memo5.png') },
	{ src: require('@/assets/images/guide/memo6.png') },
]
export const memoMobileGuideItems = [
	{ src: require('@/assets/images/guide/memo_m1.png') },
	{ src: require('@/assets/images/guide/memo_m2.png') },
	{ src: require('@/assets/images/guide/memo_m3.png') },
	{ src: require('@/assets/images/guide/memo_m4.png') },
	{ src: require('@/assets/images/guide/memo_m5.png') },
	{ src: require('@/assets/images/guide/memo_m6.png') },
]

export const carGuideItems = [
	{ src: require('@/assets/images/guide/car1.png') },
	{ src: require('@/assets/images/guide/car2.png') },
	{ src: require('@/assets/images/guide/car3.png') },
	{ src: require('@/assets/images/guide/car4.png') },
	{ src: require('@/assets/images/guide/car5.png') },
]
export const carMobileGuideItems = [
	{ src: require('@/assets/images/guide/car_m1.png') },
	{ src: require('@/assets/images/guide/car_m2.png') },
	{ src: require('@/assets/images/guide/car_m3.png') },
	{ src: require('@/assets/images/guide/car_m4.png') },
	{ src: require('@/assets/images/guide/car_m5.png') },
]

export const parkingGuideItems = [
	{ src: require('@/assets/images/guide/parking1.png') },
	{ src: require('@/assets/images/guide/parking2.png') },
	{ src: require('@/assets/images/guide/parking3.png') },
	{ src: require('@/assets/images/guide/parking4.png') },
	{ src: require('@/assets/images/guide/parking5.png') },
]
export const parkingMobileGuideItems = [
	{ src: require('@/assets/images/guide/parking_m1.png') },
	{ src: require('@/assets/images/guide/parking_m2.png') },
	{ src: require('@/assets/images/guide/parking_m3.png') },
	{ src: require('@/assets/images/guide/parking_m4.png') },
	{ src: require('@/assets/images/guide/parking_m5.png') },
]

export const DEFAULT_SECURITY_SETTING = {
	memo: false,
	memoDecryption: false,
	car: false,
	codebook: false,
	bag: false,
	calendar: false,
	map: false,
	trash: false,
};

export const DEFAULT_PUSH_SETTING = {
	server: true,
	notice: true,
	common: true,
	inquiry: true,
	car: true,
	calendar: true,
}