const userData = {
	state: {
		user: null, // user 데이터
		keepLogin: true, // 로그인 유지 여부
    onlyViewTitle: false, // 메모 제목만 보이기
    randomColor: false, // 랜덤칼라 여부
		parkingData: null, // 주차장 데이터
		codebookDataList: [], // 이전, 다음 게시글 기능을 위한 코드북 데이터 리스트
		previousCodebook: undefined, // 이전 코드북 게시글
		nextCodebook: undefined, // 다음 코드북 게시글
		guideDialogVisible: { // GuideDialog 표시여부
			memo: true,
			car: true,
			parkinglot: true,
			document: true,
			board: true,
			trash: true,
			setting: true,
		},
		serverToken: null,
		currentLocation: { lat: 37.19594112690984, lon: 127.11870547234463 }, // 현재 gps 위치
		weatherData: null,
		weatherFastData: null,
		dustData: null,
		pwaInstallGuideNotVisible: false, // pwa install GuideDialog 표시여부
		webPushPermissionRequest: false, // 최초 웹 푸시 허용 요청 여부
		carEventAlertCloseHistory: { // 차계부 이벤트 알림 닫은 기록
			carMissionOil: false, carTire: false
		},
  },
	getters: {
		/*
		* this.$store.getters.getUser
		* 형태로 호출
		*/
		getUser: (state) => {
			return state.user;
		},
		getKeepLogin: (state) => {
			return state.keepLogin;
		},
		getOnlyViewTitle: (state) => {
			return state.onlyViewTitle;
		},
    getRandomColor: (state) => {
			return state.randomColor;
		},
		getParkingData: (state) => {
			return state.parkingData;
		},
		getCodebookDataList: (state) => {
			return state.codebookDataList;
		},
		getPreviousCodebook: (state) => {
			return state.previousCodebook;
		},
		getNextCodebook: (state) => {
			return state.nextCodebook;
		},
		getGuideDialogVisible: (state) => {
			return state.guideDialogVisible;
		},
		getServerToken: (state) => {
			return state.serverToken;
		},
		getCurrentLocation: (state) => {
			return state.currentLocation;
		},
		getWeatherData: (state) => {
			return state.weatherData;
		},
		getWeatherFastData: (state) => {
			return state.weatherFastData;
		},
		getDustData: (state) => {
			return state.dustData;
		},
		getPwaInstallGuideNotVisible: (state) => {
			return state.pwaInstallGuideNotVisible;
		},
		getWebPushPermissionRequest: (state) => {
			return state.webPushPermissionRequest;
		},
		getCarEventAlertCloseHistory: (state) => {
			return state.carEventAlertCloseHistory;
		},
	},
	mutations: { // 동기 처리
		/*
		  this.$store.commit('setUser', data);
			이와 같은 형태로 호출
		*/
		setUser(state, user) {
			state.user = user;
		},
		setKeepLogin(state, data) {
			state.keepLogin = data;
		},
		setOnlyViewTitle(state, data) {
      state.onlyViewTitle = data;
    },
    setRandomColor(state, data) {
      state.randomColor = data;
    },
		setParkingData(state, data) {
      state.parkingData = data;
    },
		setCodebookDataList(state, data) {
			state.codebookDataList = data;
		},
		setPreviousCodebook(state, data) {
			state.previousCodebook = data;
		},
		setNextCodebook(state, data) {
			state.nextCodebook = data;
		},
		setGuideDialogVisible(state, data) {
			state.guideDialogVisible = data;
		},
		setServerToken(state, data) {
			state.serverToken = data;
		},
		setCurrentLocation(state, data) {
			state.currentLocation = data;
		},
		setWeatherData(state, data) {
			state.weatherData = data;
		},
		setWeatherFastData(state, data) {
			state.weatherFastData = data;
		},
		setDustData(state, data) {
			state.dustData = data;
		},
		setPwaInstallGuideNotVisible(state, data) {
			state.pwaInstallGuideNotVisible = data;
		},
		setWebPushPermissionRequest(state, data) {
			state.webPushPermissionRequest = data;
		},
		setCarEventAlertCloseHistory(state, data) {
			state.carEventAlertCloseHistory = data;
		},
	}, 
	actions: { // 비동기 처리
		
	}
}
export default userData;
